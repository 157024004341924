@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cairo";
  src: local("Cairo Regular"),
    url("../public/fonts/Cairo-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo Bold"),
    url("../public/fonts/Cairo-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}

body {
  font-family: "Cairo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  direction: ltr;
  background-color: #fff4ea;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.add {
  bottom: 65px !important;
  transition: all 0.3s;
}

.transition {
  transition: all 0.3s;
}

g.MuiChartsAxis-left > g > text {
  transform: translateX(-40px);
  text-align: center;
}

.MuiChartsLegend-mark {
  display: none;
}

.MuiChartsLegend-mark + text > tspan {
  font-size: large;
  font-weight: bold;
}

#chart-axis-1-tickLabels-4 {
  margin-right: 50px;
}
