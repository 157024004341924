.P500 {
  box-sizing: border-box;
}
.P500,
.P500::before,
.P500::after {
  box-sizing: inherit;
}
.P500 {
  font: normal 100%/1.15 "Merriweather", serif;
  color: #fff;
}
.wrapper {
  position: relative;
  max-width: 1298px;
  height: auto;
  margin: 2em auto 0 auto;
}
.box {
  max-width: 70%;
  min-height: auto;
  margin: 0 auto;
  padding: 1em 1em;
  text-align: center;
  background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1")
      no-repeat top 10em center/128px 128px,
    transparent
      url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1")
      no-repeat top 13em center/128px 128px;
}
.head500,
.paragraph500:not(:last-of-type) {
  text-shadow: 0 0 6px #216f79;
}
.head500 {
  margin: 0 0 1rem 0;
  font-size: 8em;
}
.paragraph500 {
  margin-bottom: 0.5em;
  font-size: 3em;
}
.paragraph500:first-of-type {
  margin-top: 4em;
}
.paragraph500 > a {
  border-bottom: 1px dashed #216f79;
  font-style: italic;
  text-decoration: none;
  color: #216f79;
}
.paragraph500 > a:hover {
  text-shadow: 0 0 6px #216f79;
}
.paragraph500 img {
  vertical-align: bottom;
}
